const ticketconfig = {
    'qldlogowidth': '100px',
    'location': 'bdmBrisbane',
    'hoursStart': 7,
    'hoursEnd': 18,
    apiEndpoint: function() {
        if (location.host.indexOf('dev.') == 0 || location.host.indexOf('localhost') == 0) {
            return 'https://devapi.counter.services.bdm.qld.gov.au/ticket/ticket/';
        } else if (location.host.indexOf('test.') == 0) {
            return 'https://testapi.counter.services.bdm.qld.gov.au/ticket/ticket/';
        }
        return 'https://api.counter.services.bdm.qld.gov.au/ticket/ticket/';
    }
};


export default ticketconfig;