import Vue from 'vue'
import VueRouter from 'vue-router'
import CounterTicketRequestForm from '../views/CounterTicketRequestForm.vue'
import CounterTicketDisplayForm from '../views/CounterTicketDisplayForm.vue'
import CounterTicketPrintForm from '../views/CounterTicketPrintForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/counter',
    name: 'Counter Ticket Request',
    component: CounterTicketRequestForm
  },
  {
    path: '/display',
    name: 'Counter Ticket Display',
    component: CounterTicketDisplayForm
  },
  {
    path: '/print',
    name: 'Counter Ticket Print',
    component: CounterTicketPrintForm
  },
  {
    path: '/',
    redirect: { name: 'Counter Ticket Request' }
  },
]

const router = new VueRouter({
  routes
})

export default router
