<template>
  <div>
    <div id="logo">
      <img
        width="200px"
        height="auto"
        v-bind:src="qldlogo"
        alt="Queensland Government Logo"
      />
      <i
        class="fa fa-home fa-3x homeIcon"
        v-on:click="home()"
        v-if="navStack.length > 1"
      ></i>
    </div>

    <div v-if="navStack.at(-1) == 'home'" class="form">
      <button v-on:click="navigate('certificate')">Certificates</button>
      <button v-on:click="navigate('birthregistration')">Birth Registrations</button>
      <button v-on:click="navigate('civil')">Civil Partnerships</button>
      <button v-on:click="navigate('ceremony')">Ceremonies at the Registry</button>
      <button v-on:click="navigate('changeofname')">Change of Name</button>
      <button v-on:click="navigate('alterationofsex')">Alteration of Sex</button>
      <button v-on:click="navigate('other')">Other</button>
    </div>

    <!-- Certificate -->
    <section id="certificates">
      <div v-if="navStack.at(-1) == 'certificate'" class="form">
      <button v-on:click="navigate('certificate-prepaid')">Collect a Pre-paid Certificate</button>
      <button v-on:click="navigate('certificate-unpaid')">Order a Certificate</button>
    </div>

    <div v-if="navStack.at(-1) == 'certificate-prepaid'" class="form">
      <button v-on:click="generateTicket('certificate-prepaid-births')">Births</button>
      <button v-on:click="generateTicket('certificate-prepaid-deaths')">Deaths</button>
      <button v-on:click="generateTicket('certificate-prepaid-marriages')">Marriages</button>
      <button v-on:click="generateTicket('certificate-prepaid-civil')">Civil Partnerships</button>
      <button v-on:click="generateTicket('certificate-prepaid-change')">Change of Name</button>
    </div>

    <div v-if="navStack.at(-1) == 'certificate-unpaid'" class="form">
      <button v-on:click="navigate('certificate-unpaid-births')">Births</button>
      <button v-on:click="navigate('certificate-unpaid-deaths')">Deaths</button>
      <button v-on:click="navigate('certificate-unpaid-marriages')">Marriages</button>
      <button v-on:click="navigate('certificate-unpaid-civil')">Civil Partnerships</button>
      <button v-on:click="navigate('certificate-unpaid-change')">Change of Name</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('certificate-unpaid-') == 0 && navStack.at(-1) != 'certificate-unpaid-outside' && navStack.at(-1).indexOf('-within') < 0" class="form">
      <h1>Did the event occur in Queensland?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-within')">Yes</button>
      <button v-on:click="concierge()">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('certificate-unpaid-') == 0 && navStack.at(-1) != 'certificate-unpaid-outside' && navStack.at(-1).indexOf('-within') > 0 && navStack.at(-1).indexOf('-within-filled') < 0 && navStack.at(-1).indexOf('filled') < 0" class="form">
      <h1>Have you filled in an application form?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-filled')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-unfilled')">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('certificate-unpaid-') == 0 && navStack.at(-1).indexOf('filled') > 0" class="form">
      <h1>How are you paying today?</h1>
      <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
    </div>
    </section>

    <!-- Birth Registrations -->
    <section id="birthreg">
      <div v-if="navStack.at(-1) == 'birthregistration'" class="form">
      <button v-on:click="navigate('birthregistration-register')">Register a Birth</button>
      <button v-on:click="navigate('birthregistration-registerandorder')">Register a Birth and Order a Certificate</button>
      <button v-on:click="navigate('birthregistration-addparent')">Add a Parent to a Birth Registration</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('birthregistration-') == 0 && navStack.at(-1).indexOf('-filled') < 0 && navStack.at(-1).indexOf('-within') < 0" class="form">
      <h1>Did the event occur in Queensland?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-within')">Yes</button>
      <button v-on:click="concierge()">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('birthregistration-') == 0 && navStack.at(-1).indexOf('filled') < 0 && navStack.at(-1).indexOf('-within') > 0" class="form">
      <h1>Have you filled in an application form?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-filled')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-unfilled')">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('birthregistration-') == 0 && navStack.at(-1).indexOf('filled') > 0" class="form">
      <h1>How are you paying today?</h1>
      <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
    </div>
    </section>

    <!-- Civil Partnerships -->
    <section id="civilpartnerships">
      <div v-if="navStack.at(-1) == 'civil'" class="form">
      <button v-on:click="navigate('civil-register')">Register a Civil Partnership</button>
      <button v-on:click="navigate('civil-registerandorder')">Register a Civil Partnership and Order a Certificate</button>
      <button v-on:click="navigate('civil-withdrawapplication')">Withdraw Application to Register a Civil Partnership</button>
      <button v-on:click="navigate('civil-end')">End a Civil Partnership</button>
      <button v-on:click="generateTicket('civil-enquiry')">Enquiry</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('civil-') == 0
      && navStack.at(-1).indexOf('appointment') < 0" class="form">
      <h1>Have you pre-booked an appointment?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-prebookedappointment')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-noappointment')">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('civil') == 0 
      && navStack.at(-1).indexOf('appointment') > 0
      && navStack.at(-1).indexOf('filled') < 0" class="form">
      <h1>Have you filled in an application form?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-filled')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-unfilled')">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('civil-') == 0 && navStack.at(-1).indexOf('filled') > 0" class="form">
      <h1>How are you paying today?</h1>
      <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-prepaid')">Pre-paid</button>
    </div>

    <!-- Ceremonies at the Registry -->
    <div v-if="navStack.at(-1) == 'ceremony'" class="form">
      <button v-on:click="navigate(navStack.at(-1) + 'ceremony-prebookedinterview')">Pre-booked Interview</button>
      <button v-on:click="generateTicket('ceremony-enquiry')">Enquiry</button>
    </div>

    <div 
      v-if="navStack.at(-1).indexOf('ceremony-prebookedinterview') > 0" 
      class="form">
      <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-prepaid')">Pre-paid</button>
    </div>
    </section>

    <!-- Change of Name -->
    <section id="changeofname">
      <div v-if="navStack.at(-1) == 'changeofname'" class="form">
      <button v-on:click="navigate('changeofname-apply')">Apply for Change of Name</button>
      <button v-on:click="generateTicket('changeofname-enquiry')">Enquiry</button>
    </div>

    <div v-if="navStack.at(-1) == 'changeofname-apply'" class="form">
      <button v-on:click="navigate('changeofname-apply-queensland')">Queensland born</button>
      <button v-on:click="navigate('changeofname-apply-overseas')">Overseas born</button>
      <button v-on:click="concierge()">Interstate born</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('changeofname-apply-') >= 0 && navStack.at(-1).indexOf('filled') < 0" class="form">
      <h1>Have you filled in an application form?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-filled')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-unfilled')">No</button>
    </div>
    
    <div 
      v-if="(navStack.at(-1).indexOf('changeofname-apply-') >= 0 && navStack.at(-1).indexOf('filled') >= 0) 
        && (navStack.at(-1).indexOf('booked') < 0)"
      class="form"
    >
      <h1>Have you pre-booked an appointment?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-prebooked')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-notprebooked')">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('changeofname-apply') >= 0 
      && navStack.at(-1).indexOf('booked') > 0" class="form">
      <h1>How are you paying today?</h1>
      <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-prepaid')">Pre-paid</button>
    </div>

    </section>
    
    <!-- Other -->
    <section id="other">
    <div v-if="navStack.at(-1) == 'other'" class="form">
      <button v-on:click="navigate('other-requestreview')">Request to Review a Certificate</button>
      <button v-on:click="navigate('other-norecordofmarriage')">Apply for No Record of Marriage Letter</button>
      <button v-on:click="generateTicket('other-deathormarriage')">Death or Marriage Registrations</button>
      <button v-on:click="navigate('other-surrogacy')">Surrogacy</button>
      <button v-on:click="concierge()">All Other Enquiries</button>
    </div>

    <div v-if="navStack.at(-1) == 'other-requestreview' || navStack.at(-1) == 'other-norecordofmarriage' || navStack.at(-1) == 'other-surrogacy'" class="form">
      <h1>Have you filled in an application form?</h1>
      <button v-on:click="navigate(navStack.at(-1) + '-filled')">Yes</button>
      <button v-on:click="navigate(navStack.at(-1) + '-unfilled')">No</button>
    </div>

    <div v-if="navStack.at(-1).indexOf('other-') == 0 && navStack.at(-1).indexOf('filled') > 0" class="form">
      <h1>How are you paying today?</h1>
      <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
      <button v-on:click="generateTicket(navStack.at(-1) + '-prepaid')">Pre-paid</button>
    </div>
      
    </section>

    <!-- Acknowledgement of Sex/Recognised Details Certificates -->
    <section id="alterationofsex">
      <div v-if="navStack.at(-1).indexOf('alterationofsex') == 0 
        && !(navStack.at(-1).includes('queenslandborn') || navStack.at(-1).includes('overseasborn'))" class="form">
        <button v-on:click="navigate(navStack.at(-1) + '-queenslandborn')">Queensland born</button>
        <button v-on:click="navigate(navStack.at(-1) + '-overseasborn')">Overseas and interstate born</button>
      </div>

      <div v-if="navStack.at(-1).indexOf('alterationofsex') == 0 
        && (navStack.at(-1).indexOf('queenslandborn') > 1)
        && !(navStack.at(-1).includes('apply') || navStack.at(-1).includes('booked'))" class="form">
        <button v-on:click="navigate(navStack.at(-1) + '-apply')">Apply for an Acknowledgement of Sex</button>
        <button v-on:click="generateTicket(navStack.at(-1) + '-enquiry')">Enquiry</button>
      </div>

      <div v-if="navStack.at(-1).indexOf('alterationofsex') == 0 
        && (navStack.at(-1).indexOf('overseasborn') > 1)
        && !(navStack.at(-1).includes('apply') || navStack.at(-1).includes('booked'))" class="form">
        <button v-on:click="navigate(navStack.at(-1) + '-apply')">Apply for a Recognised Details Certificate</button>
        <button v-on:click="generateTicket(navStack.at(-1) + '-enquiry')">Enquiry</button>
      </div>

      <div v-if="navStack.at(-1).indexOf('alterationofsex') == 0 && navStack.at(-1).indexOf('apply') > 0
        && !(navStack.at(-1).includes('booked'))"
        class="form">
        <h1>Have you pre-booked an appointment?</h1>
        <button v-on:click="navigate(navStack.at(-1) + '-prebooked')">Yes</button>
        <button v-on:click="navigate(navStack.at(-1) + '-notprebooked')">No</button>
      </div>
      
      <div v-if="navStack.at(-1).indexOf('alterationofsex') == 0 && navStack.at(-1).indexOf('booked') > 0
        && !navStack.at(-1).includes('filled')" class="form">
        <h1>Have you filled in an application form?</h1>
        <button v-on:click="navigate(navStack.at(-1) + '-filled')">Yes</button>
        <button v-on:click="generateTicket(navStack.at(-1) + '-unfilled')">No</button>
      </div>

      <div v-if="navStack.at(-1).indexOf('alterationofsex') == 0 && navStack.at(-1).indexOf('filled') > 0" class="form">
        <h1>How are you paying today?</h1>
        <button v-on:click="generateTicket(navStack.at(-1) + '-cash')">Cash</button>
        <button v-on:click="generateTicket(navStack.at(-1) + '-eftpos')">EFTPOS</button>
        <button v-on:click="generateTicket(navStack.at(-1) + '-prepaid')">Pre-paid</button>
      </div>
    </section>


    <div v-if="navStack.at(-1) == 'thankyou'" class="form thank-you">
      <div id="error" v-if="error">
        <h1>An error occurred - please see a concierge</h1>
        <i class="fa fa-home fa-4x homeIcon" v-on:click="home()"></i>
      </div>
      <div v-else>
      <h1>Thank you</h1>
        <div v-if="requestType.includes('unfilled')">
          <p>
            Please collect your ticket from the dispenser below and fill in the application form
          </p>
        </div>
        <div v-else>
          <p>Please collect your ticket from the dispenser below</p>
        </div>
        <p v-if="ticketNumber">Your ticket number is: {{ ticketNumber }}</p>
        <p v-else>&nbsp;</p>
        <i class="fa fa-home fa-4x homeIcon" v-on:click="home()"></i>
        <iframe style="display: none" id="ticket"></iframe>
      </div>
    </div>

    <div v-if="navStack.at(-1) == 'concierge'" class="form thank-you">
      <h1>Thank you</h1>
      <p>Please see a concierge</p>
      <i class="fa fa-home fa-4x homeIcon" v-on:click="home()"></i>
    </div>

    <!-- Back Button -->
    <div v-if="navStack.length > 1" class="back">
      <button v-on:click="back()" id="backButton">Back</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qldlogob64 from "../assets/qldlogob64.txt";
import ticketconfig from "../ticketconfig.js";

const qldlogo = "data:image/gif;base64," + qldlogob64

export default {
  name: "CounterTicketRequest",
  data() {
    return {
      navStack: ["home"],
      ticketNumber: "",
      counterOption: "",
      requestType: "",
      error: "",
      qldlogo: qldlogo,
      backToHome: null,
      apiendpoint: ticketconfig.apiEndpoint()
    };
  },
  methods: {
    home: function () {
      this.navStack = ["home"]
      this.error = ""
      clearInterval(this.backToHome);
    },
    back: function () {
      this.error = ""
      this.resetBackToHome()
      if (this.navStack.length <= 1) {
        this.home()
      } else {
        console.log('Back to', this.navStack.at(-1))
        this.navStack.pop()
      }
    },
    concierge: function() {
      this.error = ""
      this.navStack = ["concierge"]
      this.resetBackToHome()
    },
    generateTicket: function (requestType) {
      axios.defaults.withCredentials = true;
      console.log('Sending request for', requestType)
      this.navStack = ["thankyou"];
      this.requestType = requestType;
      this.ticketNumber = "";
      this.error = "";
      const counterLocation = ticketconfig.location
      axios.get(this.apiendpoint + "request/" + encodeURIComponent(counterLocation) + "/" + requestType)
        .then((resp) => {
          if (resp.data.response.response !== 'OK') {
            this.error = "Process error";
            console.error("Process error ", resp.data.response.response)
            return
          }
          this.ticketNumber = resp.data.ticketNumber;
          var dateTime = new Date();
          var iframe = document.getElementById("ticket");
          var style = `<style>
            @media print { @page { size: auto; margin: 0mm; } };

            #form{
              font-family: Avenir, Helvetica, Arial, sans-serif;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              display: flex;
              flex-flow: column wrap;
            }
            </style>`;
          var html = `<html>
          <head>
          ${style}
          </head>
          <body onload="window.print()"><br/>
          <div id="form">
            <center>
            <img
              width="${ticketconfig.qldlogowidth}"
              height="auto"
              src="${qldlogo}"
              alt="Queensland Logo"
            />
            </center>
            <center><h2>Your ticket number is</h2></center>
            <center><h1 style="font-size: 42px;">${this.ticketNumber}</h1></center>
            <center><h2>Please take a seat and wait for this number to be called</h2></center>
            <br />
            <center style="border-top: 1px solid; font-size: 18px;"><p>${dateTime.toLocaleString("en-AU").replace(",", "").toUpperCase()}</p></center>
          </div>
          </body>
          </html>`;
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(html);
          iframe.contentWindow.document.close();
        }, (error) => {
          console.error(error)
          this.error = "Error occurred"
        });
      this.resetBackToHome(5000)
    },
    navigate: function (destination) {
      console.log('Navigating to', destination)      
      this.navStack.push(destination)
      this.resetBackToHome()
    },
    resetBackToHome: function(timeout = 30000) {
      clearInterval(this.backToHome)
      this.error = ""
      this.backToHome = setTimeout(this.home, timeout)
    }
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

#logo {
  margin-top: 1.5em;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(14, [col-start] 1fr);
  justify-items: center;
  margin-bottom: 2rem;
}

#logo img {
  grid-column: col-start 7 / span 2;
  grid-row: 1;
}

#logo .homeIcon {
  grid-column: col-start 10 / span 1;
  grid-row: 1;
  cursor: pointer;
  place-self: start center;
  margin-top: 1rem;
}

.form .homeIcon {
  margin-top: 10rem;
  cursor: pointer;
}

.back {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(14, [col-start] 1fr);
  justify-items: center;
}

#backButton {
  margin-top: 7rem;
  background-color: #2c3e50;
  grid-column: col-start 5 / span 3;
  grid-row: 1;
  place-self: start;
  position: absolute;
  bottom: 2em;
  left: 3em;
  width: 6em;
}

.thank-you h1 {
  font-size: 42px;
}

.thank-you p {
  font-size: 36px;
}
</style>
