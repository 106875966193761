<template>
  <div class="app">
    <CounterTicketRequest />
  </div>
</template>

<script>
import CounterTicketRequest from '@/components/CounterTicketRequest.vue'

export default {
  name: 'CounterTicketRequestForm',
  components: {
    CounterTicketRequest
  }
}
</script>

<style scoped>
.app{
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

</style>
