<template>
  <div class="app">
    <CounterTicketPrint />
  </div>
</template>

<script>
import CounterTicketPrint from '@/components/CounterTicketPrint.vue'

export default {
  name: 'CounterTicketPrintForm',
  components: {
    CounterTicketPrint
  }
}
</script>

<style scoped>
.app{
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

</style>
