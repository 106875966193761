<template>
  <div class="app">
    <CounterTicketDisplay />
  </div>
</template>

<script>
import CounterTicketDisplay from '@/components/CounterTicketDisplay.vue'

export default {
  name: 'CounterTicketDisplayForm',
  components: {
    CounterTicketDisplay
  }
}
</script>
