<template>
  <div id="container">
    <div v-if="readingAnnouncement" class="announcement">
      <div class="serving">Now serving</div>
      <div class="ticketnumber">{{ readingTicketNumber }}</div>
      <div class="counter">Counter {{ readingCounterSeat }}</div>
    </div>
    <div v-else id="ticketlist">
      <div class="tickets" v-for="ticket in tickets" :key="ticket.counter + '-' + ticket.ticketNumber">    
        <div class="serving">Now serving</div>
        <div class="ticketnumber">{{ ticket.ticketNumber }}</div>
        <div class="counter">Counter {{ ticket.counter }}</div>
        <hr/>
      </div>
    </div>
    <div v-if="!readingAnnouncement" id="slideshow">
      <img v-if="slide == 0" src="../assets/displayslides/Birthregistrationslide.jpg" />
      <img v-if="slide == 1" src="../assets/displayslides/Familytreeslide.jpg" />
      <img v-if="slide == 2" src="../assets/displayslides/Kindynextyear.jpg" />
      <img v-if="slide == 3" src="../assets/displayslides/Prepnextyear.jpg" />
      <img v-if="slide == 4" src="../assets/displayslides/Sport.jpg" />
      <img v-if="slide == 5" src="../assets/displayslides/Zodiaclaunch.jpg" />
    </div>
    <div class="footer"> 
    <h3 class="notice">
    Due to the services we provide, ticket numbers may not be called in numerical order
    </h3>
    </div>
    <div class="footer">
      {{ this.lastUpdated }}
    </div>    
    <audio id="audio"></audio>
  </div>
</template>

<script>
import axios from 'axios'
import ticketconfig from "../ticketconfig.js";

export default {
  name: 'CounterTicketDisplay',
  data() {
    return {
      tickets: [],
      pollingTimeout: null,
      announcements: [],
      readingAnnouncement: false,
      readingTicketNumber: '',
      readingCounterSeat: '',
      lastUpdated: new Date(),
      slide: 0,
      apiendpoint: ticketconfig.apiEndpoint()
    }
  },
  created() {
    this.refreshPoll()
    this.readAnnouncements()
    this.progressSlide()
  },
  beforeDestroy() {
    clearTimeout(this.pollingTimeout)
    clearTimeout(this.progressSlideTimeout)
  },
  methods: {
    audioEnded: function() {
      console.log('Done reading announcement')
      this.readingAnnouncement = false
    },
    readAnnouncements: function() {
      if (this.readingAnnouncement) {
        console.log('Still reading pervious announcement')
        return
      }

      let announcement = this.announcements.pop()
      if (announcement) {
        console.log('Playing announcement', announcement.ticketNumber, announcement.counterSeat)
        let audio = document.getElementById('audio')
        audio.src = announcement.audio
        this.readingAnnouncement = true
        this.readingTicketNumber = announcement.ticketNumber
        this.readingCounterSeat = announcement.counterSeat
        audio.play().then(() => {
          setTimeout(this.audioEnded, 5000) // enough time to read the whole ticket
        }).catch((e) => {
            console.log(e)
            this.readingAnnouncement = false
        })
      }
    },
    refreshPoll: function() {
      try {
        this.pollData()
        this.readAnnouncements()
      } catch (err) {
        console.error(err)
      }
      this.pollingTimeout = setTimeout(this.refreshPoll, 3000)
    },
    progressSlide: function() {
      this.slide = this.slide + 1
      if (this.slide > 5) {
        this.slide = 0
      }
      this.progressSlideTimeout = setTimeout(this.progressSlide, 5000)
    },
    pollData: function() {
      axios.defaults.withCredentials = true      
      const hour = new Date().getHours()
      if (hour < ticketconfig.hoursStart || hour > ticketconfig.hoursEnd) {
        console.log('Outside of hours', hour, 'between', ticketconfig.hoursStart, 'and', ticketconfig.hoursEnd)
        return
      }

      const counterLocation = ticketconfig.location
      axios.get(this.apiendpoint + 'display/' + encodeURIComponent(counterLocation), { withCredentials: true }).then(resp => {
        this.lastUpdated = new Date()
        if (!(resp.data.tasks && Array.isArray(resp.data.tasks) && resp.data.tasks.length > 0)) {
          return
        }

        resp.data.tasks.forEach(task => {
          console.log('Displaying ', task)
          axios.get(this.apiendpoint + 'announce/' + task.id, { withCredentials: true }).then(announceResp => {
            console.log('Adding announcement')
            if (announceResp.data.announcementData) {
              this.announcements.push({
                ticketNumber: task.ticketNumber,
                counterSeat: task.counterSeat,
                audio: announceResp.data.announcementData
              })
            }

            for (var i=0; i < this.tickets.length; i++) {
              let t = this.tickets[i]
              if (typeof t === 'undefined') {
                console.error('Undefined item in ticket list - resetting it')
                this.tickets = []
                break
              }

              if (t && t.ticketNumber == task.ticketNumber && t.counter == task.counterSeat) {
                console.log('Already in list')
                return
              }
            }

            this.tickets.push({ ticketNumber: task.ticketNumber, counter: task.counterSeat })
            if (this.tickets.length > 4) {
              this.tickets = this.tickets.slice(-4)
            }
          }).catch((err) => {
            console.log(err)
          })
        })
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

.serving {
  font-weight: initial;
  font-size: 2em;
}
.ticketnumber {
  font-weight: bold;
  font-size: 3em;
}
.counter {
  font-weight: bold;
  font-size: 3em;
}
.announcement {
  height: 100%;
  width: 100%;
}
.announcement .serving {
  font-size: 18vh;
}
.announcement .ticketnumber {
  font-size: 22vh;
}
.announcement .counter {
  font-size: 22vh;
}

#slideshow {
  float: right;
  margin-top: -1em;
  padding-top: 0;
  width: 80%;
}

#slideshow img {
  object-fit: scale-down;
  width: 100%;
}

#ticketlist {
  float: left;
  width: 20%;
}

.tickets {
  
  padding-left: 1em;
}

.footer {
  display: inline-block;
  width: 100%;
}

.notice {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 1.5em;
}

#container {
  margin-top: 1em;
}

</style>
