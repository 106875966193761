<template>
  <div>
    <button @click="print()">Print test ticket</button>
    <iframe style="display: none" id="ticket"></iframe>
  </div>
</template>

<script>
import qldlogob64 from "../assets/qldlogob64.txt"
import ticketconfig from "../ticketconfig.js";

const qldlogo = "data:image/gif;base64," + qldlogob64

export default {
  name: "CounterTicketPrint",
  data() {
    return {
      ticketNumber: "123",
      qldlogo: qldlogo
    };
  },
  methods: {
    print: function() {
      var dateTime = new Date();
      var iframe = document.getElementById("ticket");
      var style = `<style>
        @media print { @page { size: auto; margin: 0mm; } };

        #form{
          font-family: Avenir, Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: flex;
          flex-flow: column wrap;
        }
        </style>`;
      var html = `<html>
      <head>
      ${style}
      </head>
      <body onload="window.print()"><br/>
      <div id="form">
        <center>
        <img
          width="${ticketconfig.qldlogowidth}"
          height="auto"
          src="${qldlogo}"
          alt="Queensland Logo"
        />
        </center>
        <center><h2>Your ticket number is </h2></center>
        <center><h1 style="font-size: 42px;">${this.ticketNumber}</h1></center>
        <center><h2>Please take a seat and wait for this number to be called</h2></center>
        <br />
        <center style="border-top: 1px solid; font-size: 18px;"><p>${dateTime.toLocaleString("en-AU").replace(",", "").toUpperCase()}</p></center>
      </div>
      </body>
      </html>`;
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();
    }
  },
};
</script>

<style scoped>

button{
  background-color: #084c84; /* Blue */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 1.5rem 2rem;
  margin: 1rem;
  font-size: 20px;
  border-radius: 8px;
  width: 200px;
  cursor:pointer;
}

</style>